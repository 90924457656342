import Vue from 'vue'
import Vuex from 'vuex'


import createPersistedState from "vuex-persistedstate"


Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    menu:[{name:"首页",path:'/'}],
    flag:true,
    flag2:false
  },
  mutations: {
    setMenu(state,data){ 
      if(data.meta.status == '0'){
        state.menu = [{name:"首页",path:'/'}]
        data.meta.name.map(item=>{
            state.menu.push({name:item.name,path:item.path})
        })
       
      }
       if(data.meta.status == 'son'){
          let num = 0
          data.meta.name.map(item=>{
            state.menu.map(it=>{
                if(item.name == it.name){
                  num++
                }
            })
         })
         if(num==0){
          state.menu.push({name:data.meta.name[0].name,path:data.meta.name[0].path})
             
         }
        }
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState({ //vuex持久化
 
    storage:window.sessionStorage
})]
})