// import './jquery-3.3.1.min.js'
function adjustMobileSize() {
    let style = document.querySelector('style.mobile')
    if (style) {
        document.head.removeChild(style)
    }
    let screenWidth = document.documentElement.clientWidth || document.body.clientWidth
    let html = document.querySelector('html')
    html.classList.remove('mobile-active')
    if (screenWidth < 450) {
        html.classList.add('mobile-active')
        let scale = screenWidth / 1400
        let style = document.createElement('style')
        style.id = 'mobile'
        style.innerHTML = `
        html.mobile.mobile-active body {
        transform: scale(${scale});}   
      `
        document.head.appendChild(style)
            // console.log(style)
    }
}

// function htmlScale() {
//   let screenWidth = document.documentElement.clientWidth || document.body.clientWidth
//   let html = document.querySelector('html')
//   if (screenWidth < 1400 && screenWidth > 1020) {
//     html.classList.add('html-scale')
//     let scale = screenWidth / 1400
//     let style = document.createElement('style')
//     style.id = 'mobile'
//     style.innerHTML = `
//       html.html-scale {
//         transform: scale(${scale});
//         transform-origin: left top;};
//       `
//     document.head.appendChild(style)
//   }
// }
window.addEventListener('resize', adjustMobileSize)
    // window.addEventListener('resize', htmlScale)
adjustMobileSize()

import './helper.scss'
import Vue from 'vue'
import store from './store'
import './style/base.css'
import 'element-ui/lib/theme-chalk/index.css';
import { Tab , Tabs , Lazyload } from 'vant'
import 'vant/lib/index.css'
//import '@/common/font/font.scss';
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Lazyload ,{
    lazyComponent:true,
    loading:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F202004%2F18%2F20200418035054_wLmeh.thumb.1000_0.gif&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1651116146&t=4fe0007e3e3e62ea92f6084497cb3f6f',
    // loading:'@/assets/loading.gif',
})

import { post, fetch } from '../src/api/https'
import Resource from 'vue-resource'
Vue.use(Resource)
import VueAjax from 'v-ajax';
import moment from 'moment'
// 二维码生成



Vue.prototype.$moment = moment
Vue.use(VueAjax);
Vue.prototype.$post = post;
Vue.prototype.$fetch = fetch;
Vue.config.productionTip = false
import axio from 'axios';
// import moment from "moment";
import {
    Input,
    Pagination,
    Radio,
    Form,
    FormItem,
    Checkbox,
    Button,
    Carousel,
    CarouselItem,
    Breadcrumb,
    BreadcrumbItem,
    Progress
}
from 'element-ui'
Vue.use(Pagination)
Vue.use(Input)
Vue.use(Radio)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Checkbox)
Vue.use(Button)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Progress)

router.beforeEach((to, from, next) => {
    store.commit('setMenu', to)
    next()
})
import App from './App.vue'
import router from './router'


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
