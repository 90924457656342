<template>
  <footer>
    <div class="bottom">
        <div class="content">
            <div class="left">
                <div class="interview-count">
                    <span>网站访问量</span>
                    <ul>
                      <li v-for="(item,key) in site_fwl" :key="key">
                          {{item}}
                      </li>
                        <!-- <li>1</li>
                        <li>3</li>
                        <li>2</li>
                        <li>7</li>
                        <li>6</li>
                        <li>8</li>
                        <li>1</li>
                        <li>2</li>
                        <li>9</li> -->
                    </ul>
                </div>
                <dl>
                    <router-link tag="dd" :to="item.menu_address" v-for="(item,key) in listed" :key="key">{{item.menu_name}}</router-link>
                    <dd></dd>
                </dl>
                <div class="copy-right">
                    <p>
                        <img src="../assets/img/jinghui.png" alt="" srcset="">
                        <a href="https://beian.miit.gov.cn/" style="color:#ede7df;" target="_blank">
                            {{banquan.site_beian}}
                            <!-- 京公网安备 1101010238123号 -->
                        </a>
                        <!-- <span>
                            京ICP备050612311号-1
                        </span> -->
                    </p>
                    <p class="copy">
                      {{banquan.site_banquan}}
                        <!-- © 2020 - 现在 顺义博物馆  网站建设:  迦尼集团旗下品牌•易龙天 -->
                    </p>
                    <p data-v-7a6844b3="" class="copy">
                        全案技术支持: 迦尼集团旗下品牌•<a data-v-7a6844b3="" href="http://www.elongtian.com" target="_blank">易龙天</a>
                    </p>
                </div>
            </div>
            <div class="right">
                <div class="Footer_margin">
                    <img src="../assets/qrCode.png" alt="" srcset="">
                    <p>顺义文旅官方账号</p>
                </div>
                <div>
                    <img src="../assets/erweima.png" alt="" srcset="">
                    <p>顺义旅游官方账号</p>
                </div>
            </div>
        </div>
      </div>
  </footer>
</template>

<script>
import { fetch, post } from "../api/https";

export default {
  data() {
    return {
      arr: [],
      list: "",
      listed: "",
      site_fwl:'',
      banquan:''
    };
  },
  created() {},
  mounted() {
    this.axio();
  },
  methods: {
    axio() {
      fetch("/getFootMenu").then((res) => {
        this.listed = res.data;
      });
      fetch("/footerinfo").then((res) => {
        this.banquan = res.data;
        this.site_fwl = String(res.data.site_fwl);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
footer {
  width: 100%;
  height: 295px;
  .bottom {
    width: 100%;
    background: url("../assets/footerBack.png");
    background-size: 100% 100%;
    height: 295px;
    position: relative;
    background-color: #a78d63;;
    padding: 40px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    // .content {
    //     height: 140px;
    // }
    .content {
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
    }
    & .left {
      // border: 1px solid green;
      text-align: center;
      color: #fff;
      width: 100%;
      // 访问数量
      & .interview-count {
        display: flex;
        justify-content: center;
        align-items: center;
        // border: 1px solid red;
        margin: 0 auto;
        width: 80%;
        span {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgb(243, 243, 245);
        }

        ul {
          display: flex;
          margin-left: 6px;
          & li {
            width: 20px;
            height: 28px;
            // background: #333333;
            // opacity: 0.1;
            box-sizing: border-box;
            line-height: 28px;
            text-align: center;
            background-color: rgba(245, 245, 245, 0.1);
            margin-left: 6px;
          }
        }
      }
      & dl {
        width: 360px;
        height: 15px;
        padding: 0;
        font-weight: 500;
        font-size: 14px;
        display: flex;
        justify-content: space-around;
        margin: 0 auto;
        padding-right: 2px;
        margin-top: 24px;
        margin-bottom: 26px;
        & dd {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
          float: left;
        }
      }

      & .copy-right {
        clear: both;
        color: #ede7df;
        text-align: center;
        font-size: 14px;
        position: relative;
        & img {
          vertical-align: middle;
          // float: left;
          // margin-top: ;
          margin: 0;
          // left: 760px;
          width: 16px;
          height: 19px;
        }
        & p {
          margin-bottom: 8px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
        }
      }
    }
    dd {
      cursor: pointer;
    }

    .copy {
      font-size: 14px;
      font-family: PingFang-SC-Regular;
      font-weight: 400;
        a {
            color: #ede7df;
            &:hover {
                 border-bottom: 1px solid #fff;
             }
        }
    }
  }
  .Footer_margin {
    margin-right: 30px;
  }
  .right {
    position: absolute;
    right: 18.5%;
    top: 100px;
    color: #f4f4f4;
    // border: 1px solid red;
    & div {
      float: left;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 12px;

      & img {
        width: 83px;
        height: 83px;
      }

      & p {
        transform: scale(0.9);

        margin-top: 8px;
      }
    }
  }
}
</style>
