import axios from 'axios';

// const BaseURL = "http://bwg.elongtian.com/api"
const BaseURL = "/api"
    // axios.defaults.baseURL = 'http://www.bowuguan.com/';
axios.defaults.baseURL = BaseURL;
// axios.defaults.withCredentials=true;

// axios.defaults.baseURL = 'http://www.bowuguan.com';


//http request 拦截器
axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token') //注意使用的时候需要引入cookie方法，推荐js-cookie
            // config.data = JSON.stringify(config.data);
            //application/x-www-form-urlencoded
            //application/json; charset=utf-8
        config.headers = {
                'Content-Type': 'application/json; charset=utf-8',
                "Access-Control-Allow-Origin": "*"
            }
            // config.params = { ...config.params, 'token':token}
        config.data = {...config.data, 'token': token }
        return config;
    },
    error => {
        return Promise.reject(err);
    }
);


//http response 拦截器
axios.interceptors.response.use(
    response => {
        if (response.data.errCode == 2) {
            router.push({
                path: "/",
                querry: { redirect: router.currentRoute.fullPath } //从哪个页面跳转
            })
        }
        return response;
    },
    error => {
        return Promise.reject(error)
    }
)


/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function fetch(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
                params: params
            })
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err)
            })
    })
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data) {
    return new Promise((resolve, reject) => {
        axios.post(url, data)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}



export function patch(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.patch(url, data)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.put(url, data)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}