<template >
  <div
    class="css-helper"
    ref="header"
    style="position: relative"
    :class="flag ? '' : 'hidden'"
  >
    <!-- <div class="header flex flex-row" :style="`transform:translateY(${headerY}px)`"> -->
    <div class="header flex flex-row" :class="$route.path== '/hotList' || $route.path== '/zhan' ? 'border-BOTTOM' : ''">
      <div class="header-nav flex-item" @click="goList">
        <img
          class="header-nav-img"
          src="../../assets/img/daohang2.png"
          alt=""
          @click="$router.push('/')"
        />
      </div>
      <div class="header-content flex-item flex-1">
        <div class="logo cursor-pointer" @click="$router.push('/')"></div>
        <ul class="menu">
          <li
            class="menu-item"
            v-for="(item, key) in menu"
            :key="key"
            :class="{
              active: [
                item.menu_address.trim(),
                ...(item.child ? item.child.map((c) => c.menu_address) : []),
              ].some((add) => isHeaderItemActive(add)),
            }"
          >
            <div
              class="menu-item-link"
              @click="gotolink(item.menu_address.trim())"
              tag="span"
              v-if="!item.child"
            >
              {{ item.menu_name }}
            </div>
            <!-- 2022-05-18 -->
            <div v-if="item.child" class="has-child-menu" ref="headerItem" @mouseenter="calculateChildMenuSize">
              <div class="title" @mouseover.stop="mouseover($event, key)" @click="gotolink(item.menu_address.trim())">
                {{ item.menu_name }}
              </div>
              <div class="child-menu" style="width: 270px;height:auto;color:#fff;text-align: center;left: -100px;z-index: 5;padding: 0 10px;box-sizing:border-box;background:rgba(176, 103, 41, 0.5);">
                <!-- <div class="child-menu-content" ref="childMenuContent"> -->
                  <div
                    class="child-menu-item" v-for="(child, cind) in item.child" :key="cind" style="margin: 20px 0;"
                    @click="gotolink(child.menu_address)" :class="{ active: isHeaderItemActive(child.menu_address) }">
                    {{ child.menu_name.trim() }}
                    <!-- <img class="child-menu-img" v-if="cind !== item.child.length - 1" src="../../assets/header/child-menu-img.png" alt="" /> -->
                  </div>
                <!-- </div> -->
              </div>
            </div>


            <!-- 原代码 -->
            <!-- <div v-if="item.child" class="has-child-menu" ref="headerItem" @mouseenter="calculateChildMenuSize">
              <div class="title" @mouseover.stop="mouseover($event, key)" @click="gotolink(item.menu_address.trim())">
                {{ item.menu_name }}
              </div>
              <div class="child-menu"
                :style="{left: `${item.left}px `,paddingLeft: `${item.childMenuLeft}px`,position: 'fixed',}">
                <div class="child-menu-content" ref="childMenuContent" style="margin-left:500px;">
                  <div
                    class="child-menu-item" v-for="(child, cind) in item.child" :key="cind" 
                    @click="gotolink(child.menu_address)" :class="{ active: isHeaderItemActive(child.menu_address) }">
                    {{ child.menu_name.trim() }}
                    <img class="child-menu-img" v-if="cind !== item.child.length - 1" src="../../assets/header/child-menu-img.png" alt="" />
                  </div>
                </div>
              </div>
            </div> -->
          </li>
          <!-- <li>
          公共服务
          
        </li> -->
        </ul>
        <div class="header-right">
          <div
            class="header-right-item search cursor-pointer"
            @click="$router.push('/search')"
          >
            <img src="../../assets/img/suos2.png" alt="" srcset="" />
          </div>
          <div
            class="header-right-item cursor-pointer text"
            @click="$router.push('/enroll')"
            v-show="!toShow"
          >
            注册
          </div>
          <div
            class="header-right-item cursor-pointer text"
            @click="$router.push('/login')"
            v-show="!toShow"
          >
            登录
          </div>
          <!-- <div class="header-right-item cursor-pointer text">会员中心</div> -->
          <router-link
            tag="div"
            class="header-right-item cursor-pointer text"
            to="/ucenter"
            v-show="toShow"
            >会员中心</router-link
          >
          <!-- <div class="header-right-item cursor-pointer text">会员中心</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetch } from "../../api/https";
export default {
  data() {
    return {
      menu: [],
      toShow: false,
      flag: true,
    };
  },
  watch: {
    $route() {
      if (this.$route.path == "/RouterList") {
        this.flag = false;
      } else {
        this.flag = true;
      }
    },
  },
  created() {
    this.toShow = !!localStorage.getItem("token");
    setInterval(() => {
      this.toShow = !!localStorage.getItem("token");
    }, 500);
  },
  mounted() {
    this.getMenu();

    // window.addEventListener('scroll',()=>{
    //   this.headerY=window.scrollY
    // },{
    //   passive:true
    // })
    window.addEventListener("resize", this.calculateChildMenuSize);
  },
  methods: {
    mouseover (e, key) {
      console.log(e)
      console.log(e.target.innerText)
      let index = key;
      if( key == 1 ) {
        index = 0
      }
      if( key == 4 ) {
        index = 1
      }
      if( key == 6 ) {
        index = 2
      }
      if( key == 7 ) {
        index = 3
      }
      if( key == 8 ) {
        index = 4
      }
      this.$refs.childMenuContent[index].style.marginLeft = e.pageX - e.layerX + 'px'
      console.log(this.$refs.childMenuContent[4].style.marginLeft)
      // if(this.$refs.childMenuContent[4].style.marginLeft){
      //   this.$refs.childMenuContent[index].style.marginLeft = '885px'
      // }
    },
    isHeaderItemActive(address) {
      let headerActiveMatcher =
        this.$route.meta && this.$route.meta.headerActiveMatcher;
      if (!headerActiveMatcher) {
        return false;
      }
      return address === headerActiveMatcher;
    },
    getMenu() {
      fetch("/getmenu").then((res) => {
        res.data.forEach((a) => {
          a.left = 0;
          a.childMenuLeft = 0;
        });
        this.menu = res.data;
        this.$nextTick(() => {
          this.calculateChildMenuSize();
        });
      });
    },
    goList() {
      this.$router.push("/RouterList");
    },
    calculateChildMenuSize() {
      // console.log('llll')
      this.$refs.headerItem;
      let items = this.menu.filter((ite) => ite.child);
      let headerRect = this.$refs.header.getBoundingClientRect();
      let screenWidth =
        document.documentElement.clientWidth || document.body.clientWidth;

      items.forEach((ite, ind) => {
        console.log(ind)
        let elRect = this.$refs.headerItem[ind].getBoundingClientRect();
        ite.left = -(elRect.x - headerRect.x);
        ite.childMenuLeft = elRect.x - headerRect.x + elRect.width / 2;

        if(ind == items.length-1){
          ite.left = "-1600"
        }
        console.log('elRect',elRect)
        console.log('ite',ite)
      });
      console.log('headerRect',headerRect)
      
    },
	gotolink(link)
	{
		console.log(link)
		window.location.href=link;
	},
  },
};
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  position: relative;
  background-color: white;
  z-index: 1000;
  // font-weight: lighter;
  font-family: PingFangSCMedium;
  .active {
    color: #B06729 !important;
    font-family: PingFang SC Heavy !important;
  }
  .header-nav {
    width: 89px;
    height: 97px;
    background: rgba(153, 153, 153, 0.1);
    position: relative;
    .header-nav-img {
      width: 34px;
      height: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .header-content {
    line-height: 97px;
    overflow: visible;
    .logo {
      background: url("../../assets/img/logo2.png") no-repeat center/contain;
      width: 185px;
      height: 97px;
      position: absolute;
      left: 122px;
    }
    .menu {
      margin-left: 22%;
      display: flex;
      justify-content: space-around;
      width: 52%;
      .menu-item {
        font-size: 16px;
        // font-family: 'PingFang SC Heavy';

        color: #333333;
        cursor: pointer;
        .menu-item-link {
          font-size: 16px;
        }
      }
      .has-child-menu {
        position: relative;
        .child-menu {
          // background: RGBA(223, 207, 190, 0.5);
          background: rgba(176, 103, 41, 0.5);
          // height: 50px;
          line-height: 30px;
          display: none;
          font-size: 16px;
          position: absolute;
          width: 400vw;
          font-family: MicrosoftYaHei;
          .child-menu-content {
            transform: translateX(-50%);
            position: absolute;
            color: #ffffff;
            .child-menu-item {
              display: inline-block;
              .child-menu-img {
                height: 17px;
                margin: 0 20px;
                transform: translateY(3px);
              }
            }
          }
        }
        &:hover {
          .child-menu {
            display: block;
            // width: 600vw !important;
            // left: -600.078px !important;
            // padding-left: 626.078px !important;
          }
          > .title:after {
            content: "";
            width: 0;
            height: 0;
            // border-bottom: 10px solid RGBA(223, 207, 190, 0.5);
            border-bottom: 10px solid rgba(176, 103, 41, 0.5);
            border-left: 10px solid transparent; /*transparent 表示透明*/
            border-right: 10px solid transparent;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            box-sizing: border-box;
          }
        }
      }
    }
    .header-right {
      font-size: 14px;
      position: absolute;
      right: 0%;
      top: 0px;
      line-height: 97px;
      padding-right: 26px;
      .header-right-item {
        display: inline-block;
        &.search {
          padding-right: 30px;
        }
        &.text {
          padding-right: 20px;
        }
      }
    }
  }
}
.hidden {
  display: none;
}
.border-BOTTOM{
  border-bottom: 1px solid #ccc;
}
.child-menu .child-menu-item:hover{
  color: #B06729 !important;
}
</style>
