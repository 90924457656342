import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
    // {
    //   path: "/",
    //   redirect: "/index",
    // },

    {
        path: '/',
        component: resolve => require(['@/views/index.vue'], resolve), // 首页
        meta: {
            status: '0',
            headerActiveMatcher: '/',
            name: 'index',
            name: [{
                name: "首页",
                path: '/'
            }]
        }
    },
    {
        path: '/introduce',
        component: resolve => require(['@/views/boDetails.vue'], resolve), // 本馆介绍
        meta: {
            status: '0',
            headerActiveMatcher: "/introduce",
            name: [{ name: "本馆介绍", path: '/introduce' }]
        }
    },
    {
        path: '/speech',
        component: resolve => require(['@/views/envelope.vue'], resolve), // 本馆概览
        meta: {
            status: '0',
            headerActiveMatcher: '/speech',
            name: [{ name: "本馆概览", path: '/speech' }]
        }
    },
    {
        path: '/notice',
        component: resolve => require(['@/views/newList.vue'], resolve), // 咨询中心
        meta: {
            status: '0',
            headerActiveMatcher: '/notice',
            name: [{ name: "资讯中心", path: "/news" }, { name: "通知公告", path: '/notice' }]
        }
    },
    {
        path: '/deta',
        component: resolve => require(['@/views/listDetails.vue'], resolve), // 详情列表
        meta: {
            status: 'son',
            name: [{ name: "详情列表", path: '/deta/:id' }],
            headerActiveMatcher: '/news'
        }
    },
    {
        path: '/online_activity',
        component: resolve => require(['@/views/online_activity.vue'], resolve), // 详情列表
        meta: {
            status: '0',
            name: [{ name: "线上活动", path: '/online_activity/:id' }],
            headerActiveMatcher: '/online_activity'
        }
    },
    {
        path: '/activity',
        component: resolve => require(['@/views/hot.vue'], resolve), // 博物活动
        meta: {
            status: '0',
            headerActiveMatcher: '/activity',
            name: [{ name: "博物活动", path: '/activity' }]
        }
    },
    {
        path: '/zhan',
        component: resolve => require(['@/views/zhanList.vue'], resolve), // 热门活动列表
        meta: {
            status: '0',
            headerActiveMatcher: '/activity',
            name: [{ name: "博物活动", path: '/activity' }, { name: "展览列表", path: '/zhan' }]
        }
    },
    {
        path: '/Introduction',
        component: resolve => require(['@/views/reMen.vue'], resolve), // 展览详情
        meta: {
            status: '0',
            headerActiveMatcher: '/activity',
            name: [{ name: "展览详情", path: '/reMen' }]
        }
    },
    {
        path: '/hotList',
        component: resolve => require(['@/views/hotList.vue'], resolve), // 活动列表
        meta: {
            status: '0',
            headerActiveMatcher: '/activity',
            name: [{ name: "博物活动", path: '/activity' }, { name: "活动列表", path: '/hotList' }]
        }
    },
    {
        path: '/hotListdeta',
        component: resolve => require(['../views/hotListdeta.vue'], resolve), // 活动列表
        meta: {
            status: 'son',
            headerActiveMatcher: '/activity',
            name: [{ name: "活动详情", path: '/hotListdeta/:id' }]
        }
    },
    {
        path: '/help',
        component: resolve => require(['@/views/help/help.vue'], resolve), // 帮助中心
        meta: {
            status: '0',
            headerActiveMatcher: '/help',
            name: [{ name: "公共服务", path: '/help' }, { name: "帮助中心", path: '/help' }]
        }
    },
    {
        path: '/publish',
        component: resolve => require(['@/views/publish.vue'], resolve), // 更新密码
        meta: {
            status: '0',
            name: [{ name: "更新密码", path: '/publish' }]
        }

    },
    {
        path: '/volunteer',
        component: resolve => require(['@/views/zhiyuan.vue'], resolve), // 志愿者报名
        meta: {
            status: '0',
            headerActiveMatcher: '/volunteer',
            name: [{ name: "公告服务", path: '/help' }, { name: "志愿者报名", path: '/volunteer' }]
        }
    },
    {
        path: '/digit',
        component: resolve => require(['@/views/allBaoGe.vue'], resolve), // 3D展馆
        meta: {
            status: '0',
            headerActiveMatcher: '/digit',
            name: [{ name: "3D展馆", path: '/digit' }]
        }
    },
    {
        path: '/3D_details',
        component: resolve => require(['@/views/3DDetails.vue'], resolve), // 3D详情
        meta: {
            status: '0',
            headerActiveMatcher: '/digit',
            name: [{ name: "3D详情", path: '/3D_details' }]
        }
    },
    {
        path: '/visit',
        component: resolve => require(['@/views/daoLan.vue'], resolve), // 导览详情
        name: 'visit',
        meta: {
            status: '0',
            headerActiveMatcher: '/visit',
            name: [{ name: "导览详情", path: '/visit' }]
        }
    },
    {
        path: '/digital',
        component: resolve => require(['@/views/digital.vue'], resolve), // 数字展馆
        meta: {
            status: '0',
            headerActiveMatcher: '/digital',
            name: [{ name: "数字展馆", path: '/digital' }]
        }
    },
    {
        path: '/enroll',
        component: resolve => require(['@/views/enroll.vue'], resolve), // 账号注册
        meta: {
            status: '0',
            headerActiveMatcher: '/enroll',
            name: [{ name: "账号注册", path: '/enroll' }]
        }
    },
    {
        path: '/password',
        component: resolve => require(['@/views/passwordAglin.vue'], resolve), // 更新密码
        meta: {
            status: '0',
            name: [{ name: "更新密码", path: '/password' }]
        }
    },
    {
        path: '/link',
        component: resolve => require(['@/views/link.vue'], resolve), // 相关链接
        meta: {
            status: '0',
            name: [{ name: "相关链接", path: '/link' }]
        }
    },
    {
        path: '/privacy_policy',
        component: resolve => require(['@/views/privacy_policy.vue'], resolve), // 隐私政策
        meta: {
            status: '0',
            name: [{ name: "隐私政策", path: '/privacy_policy' }]
        }
    },
    {
        path: '/copyright',
        component: resolve => require(['@/views/copyright.vue'], resolve), // 隐私政策
        meta: {
            status: '0',
            name: [{ name: "版权声明", path: '/copyright' }]
        }
    },

    //个人中心路由集合
    //账号设置
    {
        path: '/ucenter',
        component: resolve => require(['@/views/ucenter.vue'], resolve), // 个人中心
        meta: {
            status: '0',
            name: [{ name: "账号设置", path: '/ucenter' }]
        },
    },
    //我的预约
    {
        path: '/order',
        component: resolve => require(['@/views/ucenter/ucenter1.vue'], resolve), // 个人中心
        meta: {
            status: '0',
            name: [{ name: "我的预约", path: '/ucenter' }]
        },
    },
    //  我的活动
    {
        path: '/myActivity',
        component: resolve => require(['@/views/ucenter/ucenter2.vue'], resolve), // 我活动
        meta: {
            status: '0',
            name: [{ name: "我的活动", path: '/ucenter' }]
        },
    },
    // 我的收藏
    {
        path: '/like',
        component: resolve => require(['@/views/ucenter/ucenter3.vue'], resolve), // 个人中心
        meta: {
            status: '0',
            name: [{ name: "我的活动", path: '/ucenter' }]
        },
    },
    {
        path: '/myActivitydetails',
        component: resolve => require(['../views/ucenter/ucenterActivitydetails.vue'], resolve), // 个人中心
        meta: {
            status: '0',
            name: [{ name: "我的活动", path: '/ucenter' }]
        },
    },
    {
        path: '/Booking_details',
        component: resolve => require(['../views/ucenter/ucenterOrderdetails.vue'], resolve), // 个人中心
        meta: {
            status: '0',
            name: [{ name: "我的活动", path: '/ucenter' }]
        },
    },
    // 账号安全
    {
        path: '/safe',
        component: resolve => require(['@/views/ucenter/ucenter4.vue'], resolve), // 个人中心
        meta: {
            status: '0',
            name: [{ name: "我的活动", path: '/ucenter' }]
        },
    },
    {
        path: '/contactUs',
        component: resolve => require(['@/views/contactUs.vue'], resolve), //联系我们
        meta: {
            status: '0',
            name: [{ name: "联系我们", path: "/contactUs" }]
        }
    },
    {
        path: '/about',
        component: resolve => require(['@/views/aboutUs.vue'], resolve), // 关于我们
        meta: {
            status: '0',
            name: [{ name: "关于我们", path: "/about" }]
        }
    },
    {
        path: '/ticket',
        component: resolve => require(['@/views/newticket.vue'], resolve), // 票务预订
        meta: {
            status: '0',
            mobileUI: true,
            headerActiveMatcher: '/ticket',
            name: [{ name: "票务预订", path: "/ticket" }]
        }
    },
    {
        path: '/collection',
        component: resolve => require(['../views/Nubmer.vue'], resolve), // 关于我们
        meta: {
            status: '0',
            headerActiveMatcher: '/collection',
            name: [{ name: "关于我们", path: "/collection" }]
        }
    },
    {
        path: '/digitDetails',
        component: resolve => require(['../views/numberDetails.vue'], resolve), // 关于我们
        meta: {
            status: '0',
            headerActiveMatcher: '/collection',
            name: [{ name: "数字多宝阁", path: "/collection" }, { name: "藏品详情", path: "/digitDetails/:id" }]
        }
    },
    {
        path: '/activitydetail',
        component: resolve => require(['@/views/activityDetail.vue'], resolve), // 活动详情
        meta: {
            status: 'son',
            headerActiveMatcher: '/activity',
            name: [{ name: "活动详情", path: "/activitydetail/:id" }]
        }
    },
    {
        path: '/feedback',
        component: resolve => require(['../views/publish.vue'], resolve), // 活动详情
        meta: {
            status: '0',
            headerActiveMatcher: '/activity',
            name: [{ name: "活动详情", path: "/feedback" }]
        }
    },
    {
        path: '/search',
        component: resolve => require(['@/views/search.vue'], resolve), // 搜索
        meta: {
            status: '0',
            name: [{
                name: "搜索",
                path: "/search"
            }]
        }
    },
    {
        path: '/policy',
        component: resolve => require(['@/views/policy.vue'], resolve), // 文物政策
        meta: {
            status: '0',
            headerActiveMatcher: '/policy',
            name: [{ name: "资讯中心", path: "/news" }, { name: "文物政策", path: "/policy" }]
        }
    },
    {
        path: '/news',
        component: resolve => require(['@/views/news.vue'], resolve), // 新闻列表
        meta: {
            status: '0',
            headerActiveMatcher: '/news',
            name: [{ name: "资讯中心", path: "/news" }, { name: "新闻动态", path: '/news' }]
        }
    },
    {
        path: '/zujian',
        component: resolve => require(['@/views/zujian.vue'], resolve), // 新闻列表
        meta: {
            status: '0',
            headerActiveMatcher: '/news',
            name: [{ name: "新闻列表", path: "/zujian" }]
        }
    },
    {
        path:'/lantern',
        component:resolve => require(['@/views/lanternFestival/lantern.vue'],resolve), //元宵节列表
        // meta: {
        //     status: '0'
        // }
    },
    {
        path:'/lanternDetails',
        component:resolve => require(['@/views/lanternFestival/lanternDetails.vue'],resolve), //元宵节详情
        // meta: {
        //     status: '0'
        // }
    },
    {
        path:'/activityIndex',
        component:resolve => require(['@/views/activityIndex.vue'],resolve), //活动
        // meta: {
        //     status: '0'
        // }
    },
    {
        path: '/login',
        component: resolve => require(['@/views/login.vue'], resolve), // 登录
        meta: {
            status: '0',
            name: [{
                name: "登录",
                path: "/login"
            }]
        }
    },
    {
        path: '/RouterList',
        component: resolve => require(['../components/routerListed.vue'], resolve), // 网站地图
        meta: {
            status: '0',
            name: [{ name: "网站地图", path: "/RouterList" }]
        }
    },
    {
        path: '*',
        component: resolve => require(['../views/findnick.vue'], resolve), // 网站地图
        meta: {
            status: '0',
            name: [{ name: "找不到", path: "/RouterList" }]
        }
    },
]
const routerPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {

    return routerPush.call(this, location).catch(error => error)

}

const router = new VueRouter({
        mode: 'history',
        scrollBehavior(to, from, savedPosition) {
            return { x: 0, y: 0 }
        },
        base: process.env.BASE_URL,
        routes,

    })
    // console.log(router)
router.beforeEach((to, from, next) => {
        let mobileUI = to.meta && to.meta.mobileUI
        if (mobileUI) {
            document.querySelector('html').classList.add('mobile')
        } else {
            document.querySelector('html').classList.remove('mobile')
        }
        next()
    })
    // console.log(router)
router.beforeEach((to, from, next) => {
    let mobileUI = to.meta && to.meta.mobileUI
    if (mobileUI) {
        document.querySelector('html').classList.add('mobile')
    } else {
        document.querySelector('html').classList.remove('mobile')
    }
    let el = document.querySelector('#frame_content_view')
    if (el) {
        el.scrollTop = 0
    }
    next()
})
router.beforeEach((to, from, next) => {
    console.log(to.fullPath)
    if (to.fullPath == '/ucenter' || to.fullPath == '/order' || to.fullPath == '/myActivity' || to.fullPath == '/like' || to.fullPath == '/safe') { //如果需要跳转 ，往下走（1）
        let toekn = localStorage.getItem('token');
        if (toekn) { //判断是否登录过，如果有登陆过，说明有token,或者token未过期，可以跳过登录（2）
            next();
        } else {
            alert('请先登录')
            next('/login');
        }
    } else { //不需要跳转，直接往下走
        next();
    }
});

export default router