<template>
  <!-- <div id="app" :style="{transform:`ScaleX(${baifen})`}"> -->
  <div id="app">
    <div
      class="popup"
      :class="{ show: isPopupMessageShow, success: isPopupSuccess }"
    >
      <div>
        <img
          src="./assets/ucenter/true.png"
          class="popup-image"
          alt=""
          v-if="isPopupSuccess"
        />
        <div>{{ popupMessageContent }}</div>
      </div>
    </div>
    <div class="home css-helper" id="page" ref="backTop">
      <div class="flex flex-column frame">
        <!-- 头部 -->
        <div class="head flex-item">
          <!-- <header-com
            v-if="$route.path !== '/3D_details' && $route.path !== '/login'"
          ></header-com> -->
          <header-com
            v-show="$route.path !== '/login'"
          ></header-com>
        </div>
        <div
          id="frame_content_view"
          class="flex-item flex-1"
          :style="{ background: $route.path == '/digital' ? '#E6E7E9' : '' }"
        >
          <!-- 在router-view中写入该字段 -->
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import headerCom from "@/components/header/header.vue";
import footerCom from "@/components/footer.vue";
export default {
  components: {
    headerCom,
    footerCom,
  },
  data() {
    return {
      isPopupMessageShow: false,
      popupMessageContent: "",
      isPopupSuccess: false,
      findNo: "",
    };
  },
  created() {
    Vue.prototype.showPopupMessage = (msg, isSuccess) => {
      this.isPopupSuccess = !!isSuccess;
      this.popupMessageContent = msg;
      this.isPopupMessageShow = true;
      setTimeout(() => {
        this.isPopupMessageShow = false;
      }, 2000);
    };
  },
  mounted() {
    console.log("xxx");
    console.log(document.querySelectorAll("meta"));
  },
  methods: {},
};
</script>

<style lang="scss">
#app > .home > .frame > .flex-1 {
  overflow-y: auto;
}
#app {
  position: relative;
  transform-origin: left top;
  height: 100%;
  width: 100%;
  min-width: 1366px;
}
#app > .popup {
  box-sizing: border-box;
  position: fixed;
  width: 220px;
  height: 120px;
  background-color: RGBA(102, 102, 102, 1);
  color: #fff;
  text-align: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000000;
  border-radius: 10px;
  display: none;
  line-height: 120px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  .popup-image {
    margin-bottom: 10px;
  }
  &.show {
    display: block;
  }
  &.success {
    padding-top: 30px;
    line-height: 20px;
  }
}
#app > .home > .frame {
  height: 100%;
}
.home {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}
.head {
  // position: fixed;
  z-index: 100000;
  // height: 80px;
  overflow: visible;
  width: 100%;
  background-color: #fff;
  // visibility: hidden;
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
}
html.mobile.mobile-active {
  overflow-x: hidden;
  body {
    transform-origin: left top;
    overflow: visible;
  }
  #app {
    & > #page,
    & > #page > .frame > .flex-1 {
      overflow: visible !important;
    }
  }
}
html,
#app {
  height: 100%;
  width: 100%;
  min-width: 1366px;
}

>>> body {
  height: 100%;
}
a {
  text-decoration: none;
  color: block;
}
</style>
